import React from "react"
import { getCourseNavigation } from "../../../../../store/courses"
import LayoutCourse from "../../../../../layouts/course"

import Accent from "../../../../../components/accent"
import AudioPlayer from "../../../../../components/audio-player"
import Box from "../../../../../components/box"
import Paragraph from "../../../../../components/paragraph"
import CourseFooterNext from "../../../../../components/course-footer-next"
import Heading from "../../../../../components/heading"
import Note from "../../../../../components/note"
import Poem from "../../../../../components/poem"
import Seo from "../../../../../components/seo"
import Stack from "../../../../../components/stack"
import Text from "../../../../../components/text"

const Page = () => {
  const navigation = getCourseNavigation({ courseId: "reiseerinnerung" })

  return (
    <LayoutCourse
      footer={
        <CourseFooterNext to="/kurse/reiseerinnerung/02-dichterwerkstatt/eigenes-video" />
      }
      navigation={navigation}
    >
      <Seo title="Gehen & Sprechen" />
      <Stack>
        <Heading as="h2" level={2}>
          Gehen & Sprechen
        </Heading>
        <Paragraph>
          Hölderlin hat seine Verse oft im Gehen ausprobiert. Im Tübinger Stift
          soll er nachts die anderen Studenten dadurch gestört haben, dass er
          seine Gedichte laut vor sich hinsagte, während er in der Stube hin-
          und herlief. Genauso, wie Gedichte auch in der Antike nicht still
          gelesen, sondern im Tanz rezitiert und rhythmisch abgeschritten
          wurden.
        </Paragraph>
        <Note variant="task">
          Man merkt Hölderlins Gedichten an, so die Hölderlin-Experten, dass sie
          ein gewisses Tempo erfordern und in einem bestimmten Takt geschrieben
          wurden. Probiere das einmal aus und versuche, den Takt des Gedichts
          beim Hören mitzulaufen. Bei jedem Taktzeichen (|) machst du einen
          Schritt nach vorne.
        </Note>
        <AudioPlayer
          src={`kurse/andenken-komplett`}
          title={
            <Text sans>
              Christian Reiner liest{" "}
              <Text bold as="span">
                ›Andenken‹
              </Text>
            </Text>
          }
        />
        <Box bg="muted" p={[6, 6, 12]}>
          <Stack space={8}>
            <Heading level={4}>Andenken</Heading>
            <Poem size={[3, 3, 3, 4]} space={3}>
              <p>
                Der
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>Nord</Accent>ost
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>we</Accent>het,
              </p>
              <p>
                Der
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>lieb</Accent>ste
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>un</Accent>ter den
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>Win</Accent>den
              </p>
              <p>
                Mir,
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>weil</Accent> er
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>feu</Accent>rigen
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>Geist</Accent>
              </p>
              <p>
                Und
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>gu</Accent>te
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>Fahrt</Accent> ver
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>hei</Accent>ßet den
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>Schif</Accent>fern.
              </p>
              <p>
                <Accent>Geh</Accent> aber
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>nun</Accent> und
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>grü</Accent>ße
              </p>
              <p>
                Die
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>schö</Accent>ne Ga
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>ronne</Accent>,
              </p>
              <p>
                Und die
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>Gär</Accent>ten
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>von</Accent> Bour
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>deaux</Accent>
              </p>
              <p>
                <Accent>Dort</Accent>, wo am
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>schar</Accent>fen
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>U</Accent>
                fer
              </p>
              <p>
                <Accent>Hin</Accent>gehet der
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>Steg</Accent> und in den
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>Strom</Accent>
              </p>
              <p>
                <Accent>Tief</Accent> fällt der
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>Bach</Accent>, da
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>rü</Accent>ber
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>a</Accent>ber
              </p>
              <p>
                <Accent>Hin</Accent>schauet ein
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>e</Accent>del
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>Paar</Accent>
              </p>
              <p>
                Von
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>Ei</Accent>chen und
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>Sil</Accent>ber<Accent>pa</Accent>ppeln;
              </p>
              <br />
              <p>
                Noch
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>den</Accent>ket
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>das</Accent> mir
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>wohl</Accent> und
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>wie</Accent>
              </p>
              <p>
                Die
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>brei</Accent>ten
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>Gip</Accent>fel
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>nei</Accent>get
              </p>
              <p>
                Der
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>Ulm</Accent>wald,
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>ü</Accent>
                ber die
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>Mühl</Accent>',
              </p>
              <p>
                Im
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>Ho</Accent>fe
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>a</Accent>
                ber
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>wäch</Accent>set ein
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>Fei</Accent>gen
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>baum</Accent>.
              </p>
              <p>
                An
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>Fei</Accent>er
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>ta</Accent>gen
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>gehn</Accent>
              </p>
              <p>
                Die
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>brau</Accent>nen
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>Frau</Accent>en da
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>selbst</Accent>
              </p>
              <p>
                Auf
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>seid</Accent>nen
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>Bo</Accent>den,
              </p>
              <p>
                Zur
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>Mär</Accent>zen
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>zeit</Accent>,
              </p>
              <p>
                Wenn
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>gleich</Accent> ist
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>Nacht</Accent> und
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>Tag</Accent>,
              </p>
              <p>
                Und
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>ü</Accent>
                ber
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>lang</Accent>samen
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>Ste</Accent>gen,
              </p>
              <p>
                Von
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>gol</Accent>denen
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>Träu</Accent>men
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>schwer</Accent>.
              </p>
              <p>
                <Accent>Ein</Accent>wiegende
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>Lüf</Accent>te
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>zie</Accent>hen.
              </p>
              <br />
              <p>
                Es
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>rei</Accent>che
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>a</Accent>
                ber,
              </p>
              <p>
                Des
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>dun</Accent>keln
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>Lich</Accent>tes
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>voll</Accent>,
              </p>
              <p>
                Mir
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>ei</Accent>ner den
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>duf</Accent>tenden
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>Be</Accent>cher,
              </p>
              <p>
                Da
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>mit</Accent> ich
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>ru</Accent>hen
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>mö</Accent>ge; denn
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>süß</Accent>
              </p>
              <p>
                Wär'
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>un</Accent>ter
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>Schat</Accent>ten der
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>Schlum</Accent>mer.
              </p>
              <p>
                <Accent>Nicht</Accent> ist es
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>gut</Accent>,
              </p>
              <p>
                <Accent>Seel</Accent>los von
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>sterb</Accent>lichen
              </p>
              <p>
                Ge
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>dan</Accent>ken zu
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>seyn</Accent>. Doch
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>gut</Accent>
              </p>
              <p>
                <Accent>Ist</Accent> ein Ge
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>spräch</Accent> und zu
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>sa</Accent>gen
              </p>
              <p>
                Des
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>Her</Accent>zens
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>Mei</Accent>nung, zu
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>hö</Accent>ren
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>viel</Accent>
              </p>
              <p>
                Von
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>Ta</Accent>gen der
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>Lieb</Accent>',
              </p>
              <p>
                Und
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>Tha</Accent>ten,
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>wel</Accent>che ge
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>sche</Accent>hen.
              </p>
              <br />
              <p>
                <Accent>Wo</Accent> aber
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>sind</Accent> die
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>Freun</Accent>de?
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>Bel</Accent>lar
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>min</Accent>
              </p>
              <p>
                Mit
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>dem</Accent> Ge
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>fähr</Accent>ten?
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>Man</Accent>cher
              </p>
              <p>
                Trägt
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>Scheu</Accent>e, an die
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>Quel</Accent>le zu
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>gehn</Accent>;
              </p>
              <p>
                <Accent>Es</Accent> be
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>gin</Accent>net
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>nem</Accent>lich der
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>Reich</Accent>tum
              </p>
              <p>
                Im
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>Mee</Accent>re.
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>Sie</Accent>,
              </p>
              <p>
                Wie
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>Mah</Accent>ler,
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>brin</Accent>gen zu
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>sam</Accent>men
              </p>
              <p>
                Das
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>Schö</Accent>ne der
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>Erd</Accent>' und ver
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>schmähn</Accent>
              </p>
              <p>
                Den ge
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>flü</Accent>gelten
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>Krieg</Accent>
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>nicht</Accent>, und
              </p>
              <p>
                Zu
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>woh</Accent>nen
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>ein</Accent>sam,
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>jahr</Accent>lang,
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>un</Accent>ter
              </p>
              <p>
                Dem ent
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>laub</Accent>ten
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>Mast</Accent>, wo
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>nicht</Accent> die
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>Nacht</Accent> durch{" "}
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>{" "}
                <Accent>glän</Accent>zen
              </p>
              <p>
                Die
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>Fei</Accent>er
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>ta</Accent>ge der
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>Stadt</Accent>,
              </p>
              <p>
                Und
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>Sai</Accent>ten
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>spiel</Accent> und
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>ein</Accent>ge
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>bo</Accent>rener <Accent>Tanz</Accent>
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>nicht</Accent>.
              </p>
              <br />
              <p>
                <Accent>Nun</Accent> aber
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>sind</Accent> zu
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>In</Accent>diern
              </p>
              <p>
                Die
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>Män</Accent>ner ge
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>gan</Accent>gen,
              </p>
              <p>
                <Accent>Dort</Accent> an der
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>luf</Accent>tigen
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>Spiz</Accent>'
              </p>
              <p>
                An
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>Trau</Accent>ben
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>ber</Accent>gen,
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>wo</Accent> her
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>ab</Accent>
              </p>
              <p>
                Die Dor
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>dog</Accent>ne
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>kommt</Accent>.
              </p>
              <p>
                <Accent>Und</Accent> zu
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>sam</Accent>men
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>mit</Accent> der
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>präch</Accent>t'gen
              </p>
              <p>
                Ga
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>ronne</Accent> meer
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>breit</Accent>
              </p>
              <p>
                <Accent>Aus</Accent>gehet der
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>Strom</Accent>. Es
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>neh</Accent>met
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>a</Accent>
                ber
              </p>
              <p>
                Und
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>giebt</Accent> Ge
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>dächt</Accent>niß die
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>See</Accent>,
              </p>
              <p>
                Und die
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>Lieb</Accent>' auch
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>hef</Accent>tet
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>flei</Accent>ßig die
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>Au</Accent>gen,
              </p>
              <p>
                <Accent>Was</Accent> bleibet
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>a</Accent>
                ber,
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>stif</Accent>ten die
                <Text color="whisper" as="span">
                  {" "}
                  |{" "}
                </Text>
                <Accent>Dich</Accent>ter.
              </p>
            </Poem>
          </Stack>
        </Box>
      </Stack>
    </LayoutCourse>
  )
}

export default Page
